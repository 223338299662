import { Component, HostListener, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alert-texto',
  templateUrl: './modal-alert-texto.component.html',
  styleUrls: ['./modal-alert-texto.component.css']
})
export class ModalAlertTextoComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  title: string;
  message: string;

  ngOnInit() {
    //
  }

  @HostListener('window:keydown.control.p', ['$event'])
  @HostListener('window:keydown.control.u', ['$event'])
  @HostListener('window:keydown.control.i', ['$event'])
  @HostListener('window:keydown.control.s', ['$event'])
  @HostListener('window:keydown.shift.control.i', ['$event'])
  @HostListener('window:keydown.control.shift.i', ['$event'])
  @HostListener('window:keydown.shift.control.p', ['$event'])
  @HostListener('window:keydown.control.shift.p', ['$event'])
  @HostListener('window:keydown.shift.control.j', ['$event'])
  @HostListener('window:keydown.control.shift.j', ['$event'])
  @HostListener('window:keydown.f12', ['$event'])
  preventCtrlShift(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: KeyboardEvent) {
    event.preventDefault();
  }
}
