import { UtilsService } from './../utils/utils.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../../../environments/config';
import { AlertService } from '../alert/alert.service';
import { LogService } from '../log/log.service';
import { NativeInterfaceService } from '../native-interface/native-interface.service';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material';

const NAME = 'SICNL';
const TITLE = 'Sistema CANAL FGTS';
const VERSION = '1.0.0';

@Injectable()
export class ApplicationService {

  constructor(private router: Router, private route: ActivatedRoute, private alertService: AlertService,
    private nativeInterface: NativeInterfaceService, private log: LogService, private utilService: UtilsService) {
    //
  }

  app: any = null;
  timer = null;

  getParam(name: string, success) {
    this.route
      .queryParams
      .subscribe(params => {
        const result = params[name] || null;
        if (success) {
          success(result);
        }
      });
  }

  getState(): string {
      return this.router.url;
  }

  version(): string {
    return VERSION;
  }

  getTitle(): string {
    return TITLE;
  }

  getName(): string {
    return NAME;
  }

  getVersion(): string {
    return VERSION;
  }

  setApp(data) {
    this.app = data;
  }
  getApp(): string {
    return this.app;
  }

  goHome() {
    this.openPage('/');
  }

  goAuth() {
    this.router.navigate(['login']);
  }

  openPage(page, data = null) {
    if (this.getIdUsuario() == null) {
      this.goAuth();
      return;
    }
    if (data) {
      this.router.navigate([page, data]);
    } else {
      this.router.navigate([page]);
    }
  }

  getSelectedIndexText(o) {
    return (<HTMLSelectElement>document.getElementById(o)).options[(<HTMLSelectElement>document.getElementById(o)).selectedIndex].innerText;
  }

  getSelectedIndexValue(o) {
    return (<HTMLSelectElement>document.getElementById(o)).options[(<HTMLSelectElement>document.getElementById(o)).selectedIndex].value.split(': ')[1];
  }

  getInputCheckboxValue(o) {
    return (<HTMLInputElement>document.getElementById(o)).checked;
  }

  getInputTextValue(o) {
    return (<HTMLInputElement>document.getElementById(o)).value;
  }

  /**
   * Retorna a lista de itens selecionados de um componente "multiselect".
   *
   * @param arraylist A lista de itens selecionados.
   * @param atrib O atributo que deseja buscar na lista. Exemplos: "id" ou "itemName".
   * @returns A lista de itens no formato string.
   */
  getLista(arraylist, atrib) {
    let res = null;
    let arrayStr: Array<string> = null;
    if (arraylist != null && arraylist !== '') {
      if (atrib === 'id') {
        arrayStr = arraylist.map(o => !isNaN(o.id) ? o.id : o.id.trim());
      } else if (atrib.replace(/\s/g, '') !== '') {
        arrayStr = arraylist.map(o => o.itemName.trim());
      }
    }
    if (arrayStr !== null && arrayStr.length > 0) {
      res = JSON.stringify(arrayStr);
      res = res.replace('[', '').replace(']', '');
    }
    return res;
  }

  getListaAlterado(arraylist, atrib) {
    let res = this.getLista(arraylist, atrib);
    if (res !== null) {
      res = this.utilService.replaceAll(res, '\"', '');
      res = this.fixedEncodeUri(res);
    }
    return res;
  }

  /**
   * Retorna a Lista a ser apresentada numa tela ou arquivo pdf, xls, txt, etc.
   *
   * @param arraylist A lista de itens selecionados.
   * @param atrib O atributo que deseja buscar na lista. Exemplos: "id" ou "itemName".
   * @returns A Lista a ser apresentada numa tela ou arquivo pdf, xls, txt, etc.
   */
  showLista(arraylist, atrib) {
    let res = '';
    const vm = this;
    if (arraylist != null && arraylist.length > 0) {
      const lis = vm.getLista(arraylist, atrib);
      res = lis.replace(/"/g, '').replace(/,/g, ', ');
    }
    if (res === '') {
      res = 'Todos';
    }
    return res;
  }

  /**
   * Retorna à tela anterior.
   *
   * @param id O identificador da rota que chamou esta tela.
   * @param delay O tempo de espera para retornar.
   * @param event O evento.
   * @param message A mensagem de texto a ser exibida na tela.
   * @returns
   */
   openViewDelayed(id, delay, event= null, message= null) {
    const vm = this;
    if (id == null || id.length === 0) {
      if (message == null) {
        message = 'Procedimento executado com sucesso';
      }
      vm.alertService.showWarning('Atenção', message, 0, 3000);
      return;
    }
    if (vm.timer != null) {
      clearTimeout(vm.timer);
      vm.timer = null;
    }
    vm.timer = setTimeout(() => {
      vm.openPage(id);
    }, delay * 1000);
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  getValueFromJwt(pos) {
    let res = null;
    const token = this.nativeInterface.getPreference(Config.storageKeys.token, null);
    if (token !== null) {
      const jwtDecoded = this.parseJwt(token);
      if (jwtDecoded && typeof jwtDecoded.sub !== 'undefined') {
        const lista = jwtDecoded.sub.split(';');
        if (lista && typeof lista !== 'undefined' && lista.length >= 5) {
          res = lista[pos];
        }
      }
    }
    return res;
  }

  getCpfUsuario(): string {
    return this.getValueFromJwt(0);
  }

  getIdUsuario(): number {
    return +this.getValueFromJwt(1);
  }

  getNomeUsuario(): string {
    return this.getValueFromJwt(2);
  }

  getPerfilUsuario(): string {
    return this.getValueFromJwt(3);
  }

  isTermoAssinado(): boolean {
    const assin = this.getValueFromJwt(4);
    return assin && assin === 'S';
  }

  getPerfilFuncionalidades(): any[] {
    let res = null;
    const list = this.nativeInterface.getPreference(Config.storageKeys.tokenAccess, null);
    if (list !== null) {
      const lista = this.parseJwt(list);
      res = JSON.parse(lista.sub);
    }
    return res;
  }

  booleanToChecked(b: boolean): string {
    return b ? 'checked' : '';
  }

  getVerboCRUD(str: string, captalize: boolean): string {
    let sr = '';
    switch ( str.toLowerCase() ) {
      case 'i' : sr = 'incluir'; break;
      case 'a' : sr = 'alterar'; break;
      case 'e' : sr = 'excluir'; break;
      case 'b' : sr = 'baixar'; break;
      case 'r' : sr = 'renomear'; break;
      case 'm' : sr = 'movimentar'; break;
      default : sr = 'consultar'; break;
    }
    if ( captalize ) {
      sr = sr.charAt(0).toUpperCase() + sr.slice(1);
    }
    return sr;
  }

  /**
   * Verifica se o Perfil do Usuário tem autorização de acesso a uma Funcionalidades.
   *
   * @param link O link da funcionalidade.
   * @returns indicador de associação perfil x funcionalidade - true ou false.
   */
   temAutorizacao(link: string, oper?: string): boolean {
     let res = false;
     res = !link || link == null || typeof link === 'undefined' || link.trim() === '';
     if (!res) {
      link = link.split(';')[0];
      const list = this.getPerfilFuncionalidades().filter((d) => d.linkFuncionalidade === link); // .map((f) => f.consulta);
      res = (typeof list !== 'undefined' && list && list.length > 0);
      // res = JSON.parse(list[0])
      if (res && oper && oper !== null) {
        if (oper === 'I') {
          res = list[0].icPerfilInclusao && list[0].icPerfilInclusao === 'S';
        } else if (oper === 'A') {
          res = list[0].icPerfilAlteracao && list[0].icPerfilAlteracao === 'S';
        } else if (oper === 'E') {
          res = list[0].icPerfilExclusao && list[0].icPerfilExclusao === 'S';
         }
      }
    }
    return res;
  }

  getPerfilPastas(): any[] {
    let res = null;
    const list = this.nativeInterface.getPreference(Config.storageKeys.tokenAccessPastas, null);
    if (list !== null) {
      const lista = this.parseJwt(list);
      res = JSON.parse(lista.sub);
    }
    return res;
  }

  getPerfilPastaRaiz(): string {
    let res = null;
    const list = this.getPerfilPastas();
    if (typeof list !== 'undefined' && list && list.length > 0 && typeof list[0].link !== 'undefined') {
      res = list[0].link;
    }
    return res;
  }

  getUrl() {
    let res = `${environment.endpoint}`;
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      if (res == null || res === '') {
        res = 'http://localhost:8080';
      }
    }
    return res;
  }

  sortData(sort: Sort, tblData: any[]): any[] {
    const data = tblData.slice();
    if (!sort.active || sort.direction === '' || tblData == null) {
      return data;
    }
    return data.sort((a, b) => {
      return compare(a[sort.active], b[sort.active], sort.direction === 'asc');
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  fixedEncodeUri(uri: string): string {
    let res = null;
    if (uri != null) {
      // encodeURIComponent escapa todos os caracteres, exceto: alfanumérico e - _ . ! ~ * ' ( )
      res = this.encodeUriComponentRfc(uri);
    }
    return res;
  }

  /**
   * Converte texto usando RTC3986 para encoding utf-8 hexadecimal.
   *
   * @param str O texto.
   */
  encodeUriComponentRfc(str) {
    return encodeURIComponent(str).replace(
      /[!.'()*_~-]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    );
  }

  encodeUri(uri: string): string {
    let res = uri;
    if (res != null) {
      res = encodeURIComponent(res);
    }
    return res;
  }

  decodeUri(uri: string): string {
    let res = uri;
    if (this.isEncoded(uri)) {
      res = decodeURIComponent(uri);
    }
    return res;
  }

  isEncoded(uriParam) {
    let res = false;
    const uri = uriParam || '';
    try {
      res = uri !== decodeURIComponent(uri);
    } catch (e) {
      console.error('uri inválida - ' + uri.substring(0, 15));
    }
    return res;
  }
}
